export default {
  branding: {
    productName: "Meine Krone",
    companyName: "Glücksfaktor 7 GMBH",
    companyUrl: "https://www.kleinekrone.de",
    productUrl: "www.meinekrone.de",
    startingYear: "2019",
    companyPrivacyUrl: "https://www.kleinekrone.de/privacy",
    googlePlayUrl:
      "https://play.google.com/store/apps/details?id=com.szentertainment.kleinekrone",
    appStoreUrl: "http://itunes.apple.com/app/id1471196187",
    previewImage: "https://www.kleinekrone.de/preview.jpg",
    title: "meta.title",
    description: "meta.description",
    seoDefaultDescription: "meta.description",
  },
  currencies: {
    primary: {
      single: require("@/assets/img/common/coin1.png"),
      large: require("@/assets/img/common/coin1_big.png"),
      accent: require("@/assets/img/common/coin.png"),
      triple: require("@/assets/img/common/three-coins.png"),
      heap: require("@/assets/img/treasury/info-gold.png"),
    },
    secondary: {
      ratio: "4x3",
      single: require("@/assets/img/common/crown2.png"),
      large: require("@/assets/img/common/crown2_big.png"),
      accent: require("@/assets/img/common/crown.png"),
      triple: require("@/assets/img/common/three-crowns.png"),
    },
    tertiary: {
      single: require("@/assets/img/common/elixir.png"),
    },
  },
  assets: {
    logos: {
      small: require("@/assets/img/logo/krone-logotype-vert.png"),
      large: require("@/assets/img/logo/full_logo-short.png"),
      multipart1: require("@/assets/img/logo/title-meine.png"),
      multipart2: require("@/assets/img/logo/title-krone.png"),
      accent: require("@/assets/img/logo/landing-crown.png"),
      monochrome: require("@/assets/img/logo/logo-kleinekrone.svg"),
      slotPlaceholder: require("@/assets/img/game/placeholder.jpg"),
      homeButton: require("@/assets/img/header/btn-home.png"),
      logoutIcon: require("@/assets/img/settings/icon-logout.svg"),
      newsletterVisual: require("@/assets/img/profile/newsletter-visual.png"),
    },

    features: {
      loyalty: {
        icon: require("@/assets/img/common/loyalty.png"),
        accent: require("@/assets/img/common/loyalty-bonus.png"),
      },
      league: {
        icon: require("@/assets/img/nav-menu/nav-icon-league.png"),
        hero: require("@/assets/img/hero-images/hero-pic-feature-royalleague-default.png"),
        logo: {
          en: require("@/assets/img/hero-images/royalleague-hero-logo-en.png"),
          de: require("@/assets/img/hero-images/royalleague-hero-logo-de.png"),
        },
      },
      retention: {
        icon: require("@/assets/img/nav-menu/nav-icon-bonusslot.png"),
        headlineOff: require("@/assets/img/bonus-slot/bs-main-hl-normal.png"),
        headlineOn: require("@/assets/img/bonus-slot/bs-main-hl-glow.png"),
        coinDeco1: require("@/assets/img/bonus-slot/bs-main-coins-left.png"),
        coinDeco2: require("@/assets/img/bonus-slot/bs-main-coin-right.png"),
        crownDeco: require("@/assets/img/bonus-slot/bs-main-crowns.png"),
      },
      cards: {
        icon: require("@/assets/img/common/cardstack-3.png"),
        triple: require("@/assets/img/nav-menu/nav-icon-cards.png"),
        accent: require("@/assets/img/common/cards.png"),
        small: require("@/assets/img/common/card.png"),
        hero: require("@/assets/img/hero-images/hero-pic-feature-cards-default.jpg"),
      },
      achievements: {
        icon: require("@/assets/img/nav-menu/nav-icon-achievements.png"),
      },
      pvp: {
        icon: require("@/assets/img/nav-menu/nav-icon-champions.png"),
        hero: require("@/assets/img/hero-images/hero-pic-feature-champions-default.png"),
        logo: require("@/assets/img/champions/kk-champions-logo.png"),
      },
      social: {
        icon: require("@/assets/img/nav-menu/nav-icon-friends.png"),
        hero: require("@/assets/img/hero-images/hero-pic-feature-teams-default.png"),
        vaultVisual: require("@/assets/img/friends/goldfellowship.png"),
      },
      path: {
        icon: require("@/assets/img/nav-menu/nav-icon-kingspath.png"),
      },
      mission: {
        icon: require("@/assets/img/mission/chests/chest-5-king.png"),
        goldDisplay: require("@/assets/img/mission/mission-gold-display.png"),
      },
      tournament: {
        icon: require("@/assets/img/nav-menu/nav-icon-tournaments.png"),
        endIcon: require("@/assets/img/slot/sidebar/tourney-end-checkmark.png"),
        hero: {
          en: require("@/assets/img/hero-images/hero-logo-tourney-en.png"),
          de: require("@/assets/img/hero-images/hero-logo-tourney-de.png"),
        },
      },
      profile: {
        hero: require("@/assets/img/hero-images/hero-pic-profile.png"),
      },
      booster: {
        icon: require("@/assets/img/common/booster.png"),
      },
      treasury: {
        goldDisplay: require("@/assets/img/treasury/luxury-display-gold.png"),
      },
      shop: {
        visual: require("@/assets/img/shop/shop-visual-default.png"),
        icon: require("@/assets/img/header/icon-shop.png"),
      },
    },
    events: {
      spin: {
        coinVisual: require("@/assets/img/events/frame-with-coin.png"),
      },
    },
    offers: {
      lobby3: {
        de: require("@/assets/img/shop/offers/offer-overlay-jetztodernie_branded_de.png"),
        en: require("@/assets/img/shop/offers/offer-overlay-jetztodernie_branded_en.png"),
      },
      winterhere1: {
        de: require("@/assets/img/shop/offers/winterda-logo-de.png"),
        en: require("@/assets/img/shop/offers/winterda-logo-en.png"),
      },
      winter1: {
        de: require("@/assets/img/shop/offers/dwn-logo-de.png"),
        en: require("@/assets/img/shop/offers/dwn-logo-en.png"),
      },
      specialOffer: {
        onePanel: require("@/assets/img/shop/offers/special-1.png"),
        twoPanels: require("@/assets/img/shop/offers/special-2.png"),
      },
      bailout: {
        primary: {
          onePanel: require("@/assets/img/shop/offers/bailout-primary-1.png"),
          twoPanels: require("@/assets/img/shop/offers/bailout-primary-2.png"),
          threePanels: require("@/assets/img/shop/offers/bailout-primary-3.png"),
        },
        secondary: {
          onePanel: require("@/assets/img/shop/offers/bailout-secondary-1.png"),
          twoPanels: require("@/assets/img/shop/offers/bailout-secondary-2.png"),
          threePanels: require("@/assets/img/shop/offers/bailout-secondary-3.png"),
        },
      },
      levelup: {
        onePanel: require("@/assets/img/shop/offers/levelup-1.png"),
        twoPanels: require("@/assets/img/shop/offers/levelup-2.png"),
        threePanels: require("@/assets/img/shop/offers/levelup-3.png"),
      },
    },
    modals: {
      welcome: {
        de: require("@/assets/img/welcome/logo-de.png"),
        en: require("@/assets/img/welcome/logo-en.png"),
      },
    },
  },
  featuresEnabled: {
    paypal: false,
    facebook: true,
  },
};
